<div *ngIf="language" class="row">
    <div class="col-12 d-flex justify-content-end">
        <div class="btn-group btn-group-sm mb-3" dropdown placement="bottom right">
            <button
                id="button-basic"
                dropdownToggle
                type="button"
                class="btn btn-outline-primary dropdown-toggle d-flex align-items-center"
                aria-controls="dropdown-basic"
            >
                <img class="me-1" alt="" src="/assets/flags/{{ language.id }}.png" />
                <span *ngIf="language.id === 'nl'">Nederlands</span>
                <span *ngIf="language.id === 'en'">English</span>
                <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                <li role="menuitem">
                    <a class="dropdown-item d-flex align-items-center" (click)="goToLanguage('nl')">
                        <img class="me-1" alt="" ng-reflect-src="/assets/flags/nl.png" src="/assets/flags/nl.png" /> Nederlands
                    </a>
                </li>
                <li role="menuitem">
                    <a class="dropdown-item d-flex align-items-center" (click)="goToLanguage('en')">
                        <img class="me-1" alt="" ng-reflect-src="/assets/flags/en.png" src="/assets/flags/en.png" /> English
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="row" *ngIf="form">
    <div class="col-12 col-lg-10">
        <m3-calc-objects [form]="form" [m3Object]="m3Object"></m3-calc-objects>
    </div>
    <div class="col-12 offset-md-4 offset-lg-0 col-md-4 col-lg-2">
        <m3-calc-total [form]="form" [m3Object]="m3Object" [listItems]="true"></m3-calc-total>
    </div>
</div>

<div class="row mt-3">
    <div class="col-12 d-flex justify-content-end">
        <button *ngIf="form" class="btn btn-primary" (click)="submit()" [disabled]="form.invalid || isSubmitting">
            <fa-icon *ngIf="!isSubmitting" [icon]="['fal', 'check']" [fixedWidth]="true"></fa-icon>
            <fa-icon *ngIf="isSubmitting" [icon]="['fal', 'spinner-third']" [fixedWidth]="true" [spin]="true"></fa-icon>
            {{ language.m3Calculator.form.continueEstimate }}
        </button>
    </div>
</div>
